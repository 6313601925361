exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adultortho-js": () => import("./../../../src/pages/adultortho.js" /* webpackChunkName: "component---src-pages-adultortho-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-drgolden-js": () => import("./../../../src/pages/drgolden.js" /* webpackChunkName: "component---src-pages-drgolden-js" */),
  "component---src-pages-earlytreatment-js": () => import("./../../../src/pages/earlytreatment.js" /* webpackChunkName: "component---src-pages-earlytreatment-js" */),
  "component---src-pages-funstuff-js": () => import("./../../../src/pages/funstuff.js" /* webpackChunkName: "component---src-pages-funstuff-js" */),
  "component---src-pages-generalinfo-js": () => import("./../../../src/pages/generalinfo.js" /* webpackChunkName: "component---src-pages-generalinfo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invisalign-js": () => import("./../../../src/pages/invisalign.js" /* webpackChunkName: "component---src-pages-invisalign-js" */),
  "component---src-pages-typesofbraces-js": () => import("./../../../src/pages/typesofbraces.js" /* webpackChunkName: "component---src-pages-typesofbraces-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

